import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";

import Site from "./page/Site";

import "./App.scss";

function App() {
  return (
    <Router>
      <Route exact path="/" render={(routeProps) => <Site {...routeProps} />} />
      <Route
        exact
        path="/:lang"
        render={(routeProps) => <Site {...routeProps} />}
      />
    </Router>
  );
}

export default App;
