import React, { Component } from "react";
import Slider from "react-slick";

import Header from "./Header";
import swedbank from "../images/swedbank-tag.png";
import unicredit from "../images/citadele_logo.png";
import luminor from "../images/luminor_logo_with_bg.svg";
import seb from "../images/seb_logo.svg";

import Fabia from "../images/Fabia.png";
import Scala from "../images/Scala.png";
import Octavia from "../images/Octavia.png";
import Octavia_combi from "../images/Octavia_Combi.png";
import Superb from "../images/Superb.png";
import Karoq from "../images/Karoq.png";
import Kodiaq from "../images/Kodiaq.png";

const settings = {
  dots: false,
  arrows: false,
  infinite: true,
  autoplay: true,
  fade: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  pauseOnHover: false,
};

class Site extends Component {
  constructor(props) {
    super(props);
    this.state = {
      language: "lv",
    };
  }

  componentDidMount = () => {
    if (this.props.location.pathname === "/ru") {
      this.setState({ language: "ru" });
    }
  };

  changeLanguage = (lang) => {
    if (lang === "lv") {
      lang = "ru";
    } else {
      lang = "lv";
    }
    this.setState({
      language: lang,
    });
  };

  render() {
    return (
      <div className="App">
        <Header
          changeLanguage={this.changeLanguage}
          lang={this.state.language}
        />
        <div className="App-main">
          <div className="header">
            {this.state.language === "ru" ? (
              <React.Fragment>
                <h1>ХОККЕЙНАЯ ВЕСНА ŠKODA</h1>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <h1>ŠKODA HOKEJA PAVASARIS</h1>
              </React.Fragment>
            )}
          </div>
          <div className="background">
            <div className="bg"></div>
          </div>
          <div className="selection">
            <div className="subtitle">
              {this.state.language === "ru" ? (
                <React.Fragment>
                  <p>В СОТРУДНИЧЕСТВЕ С</p>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <p>SADARBĪBĀ AR</p>
                </React.Fragment>
              )}
            </div>
            <Bank
              settings={settings}
              logo={swedbank}
              language={this.state.language}
              url="swedbank.skoda.lv"
              buttonType="otherThing"
            />
            <Bank
              settings={settings}
              logo={seb}
              language={this.state.language}
              url="seb.skoda.lv"
              buttonType="otherThing"
            />
            <Bank
              settings={settings}
              logo={unicredit}
              language={this.state.language}
              url="citadele.skoda.lv"
              buttonType="otherThing"
            />
            <Bank
              settings={settings}
              logo={luminor}
              language={this.state.language}
              url="luminor.skoda.lv"
              buttonType="otherThing"
            />
          </div>
        </div>
      </div>
    );
  }
}

class Bank extends Component {
  render() {
    const { language, url, logo, buttonType } = this.props;
    return (
      <div className="bank">
        <a
          className="App-link"
          href={`http://${url}${language === "ru" ? "/ru" : ""}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="logoContainer">
            <img className="bank-logo" src={logo} alt="" />
          </div>
          <button className={`${language} ${buttonType}`}>
            {buttonType === "otherThing" ? language === "ru"
              ? "ПОСМОТРЕТЬ ПРЕДЛОЖЕНИЯ"
              : "APSKATĪT PIEDĀVĀJUMU" : ""}
          </button>
        </a>
      </div>
    );
  }
}

export default Site;
