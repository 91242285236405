import React, { Component } from "react";

import logo from "../images/skoda_logo.png";

class Header extends Component {
  constructor(props) {
    super(props);
  }

  changeLang = () => {
    this.props.changeLanguage(this.props.lang);
  };

  render() {
    return (
      <div className="navbar">
        <div className="logo">
          <img src={logo} alt="kamplv" />
        </div>
        <div className="languagePicker">
          <a
            href="https://www.skoda.lv/"
            target="_blank"
            rel="noopener noreferrer"
          >
            skoda.lv
          </a>
          <button onClick={() => this.changeLang()}>
            {this.props.lang === "ru" ? "LV" : "RUS"}
          </button>
        </div>
      </div>
    );
  }
}

export default Header;
